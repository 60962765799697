<template>
  <ul id="vertical-menu-navigation">
    <template
      v-for="item in items"
    >
      <component
        :is="resolveNavItemComponent(item)"
        v-if="item.authorize ? roleCode !== '' ? item.authorize.includes(roleCode) :false : false"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import store from '@/store'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  setup() {
    const roleCode = ref('')
    provide('openGroups', ref([]))

    const getSelf = () => {
      store.dispatch('app-user/getSelf')
        .then(response => {
          store.state.currentUser = response.data
          roleCode.value = response.data.role.code
        })
    }
    getSelf()

    return {
      roleCode,
      resolveNavItemComponent,
    }
  },
}
</script>
